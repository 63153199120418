<template>
        <object :data="Constants.ServerUrl + 'dashboard_mecanic/fisa_risc_pdf/' + IdFisa" type="application/pdf" width="100%" :height="parentHeight">
            <p><a :href="Constants.ServerUrl + 'dashboard_mecanic/isa_risc_pdf/' + IdFisa"></a></p>
        </object>  
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage' 
import Constants from '@/backend/Constants'
export default {
    name: "Fisa_risc_pdf",
    extends: BasePage,
    extends: BasePage,

    data(){
        return {
            Constants: Constants,
            IdFisa: 0,   
            parentHeight: 1000,        
        }
    },
    methods: {
        genereaza() {  
            this.IdFisa = this.$route.params.id    
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>